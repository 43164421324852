<template>
  <span
    class="text-white p-0.5 ml-1 text-xs mt-0.5 cursor-pointer relative"
    :class="
      !MatchDeleteConfirmationRange(index)
        ? 'bg-dark-green hover:bg-light-green'
        : 'bg-red-500 hover:bg-red-400'
    "
    title="Click to delete"
    v-for="(hours, index) in openingHours"
    :key="index"
    @click="showDeleteIcon(index)"
  >
    <font-awesome-icon
      v-show="MatchDeleteConfirmationRange(index)"
      icon="trash-alt"
      class="text-red-700 position"
      title="Confirm Delete"
    />
    {{ parsedDate(hours[0], false) }} - {{ parsedDate(hours[1], true) }}
  </span>
</template>

<script>
/* eslint-disable */
import { inject, ref } from "vue";
export default {
  emits: ["deteleRange"],
  props: {
    openingHours: {
      type: [Object, Array],
      required: true,
    },
    id: {
      type: String,
      default: "monday",
    },
  },

  setup(props) {
    const moment = inject("moment");
    const range = ref(null);
    const deleteRange = inject("deleteRange");

    const parsedDate = (date, endDate = false) => {
      date = date.split(":");
      const hour = date.length ? date[0] : 0;
      const minute = date.length && date.length >= 2 ? date[1] : 0;
      const second = date.length && date.length >= 3 ? date[2] : 0;
      let returnedTime = moment()
        .set({ hour, minute, second, millisecond: 0 })
        .format("HH:mm");
      if (returnedTime === "00:00" && endDate === true) returnedTime = "24:00";
      return returnedTime;
    };

    const showDeleteIcon = (index) => {
      if (range.value === index) {
        deleteRange({ day: props.id, index });
        cancelConfirmation();
      } else range.value = index;
      setTimeout(() => cancelConfirmation(), 5000);
    };

    const cancelConfirmation = () => {
      range.value = null;
    };

    const MatchDeleteConfirmationRange = (index) => {
      return range.value != null && range.value == index;
    };

    return {
      range,
      parsedDate,
      showDeleteIcon,
      cancelConfirmation,
      MatchDeleteConfirmationRange,
    };
  },
};
</script>

<style lang="scss" scoped>
.position {
  position: absolute;
  top: -70%;
  right: 50%;
}
span {
  width: 88px;
  height: 20px;
}
</style>