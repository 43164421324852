<template>
    <span class="text-dark-green text-lg font-inter font-normal absolute left-1/2" 
    style="transform: translateX(-50%);" v-if="isFullDay">
        24/7
    </span>
</template>

<script>
/* eslint-disable */
import { computed } from 'vue'
export default {
    props: {
        openingHours: {
            type: [Object, Array],
            required: true,
        }
    },
    setup(props) {
        const isFullDay = computed(() => {
            const timeRange = props.openingHours.length ? props.openingHours[0] : []
            if(!timeRange.length) return false
            
            if(isNaN(Date.parse(timeRange[0]))) {
                return timeRange[0].split(':')[0] == '00' 
                && ['00', '23', '24'].includes(timeRange[1].split(':')[0])
            }
            return new Date(timeRange[0]).getHours() == 0 && new Date(timeRange[1]).getHours() == 23
        })
        return { isFullDay }
    }
}
</script>
