<template>
  <div :id="id" :class="{ closed: !values.length }"></div>
</template>

<script>
/* eslint-disable */
import { onMounted, computed, inject, watch } from "vue";
import $ from "jquery";
import moment from "moment";
import RangeBar from "elessar";

export default {
  name: "ElessarSlider",

  props: {
    id: {
      required: true,
      type: [String, Number],
    },
    values: {
      type: Array,
    },
  },

  emits: ["changeRange"],

  setup(props, { emit }) {
    const deletedRangeIndex = inject("deletedRangeIndex");
    const setDeleteRange = inject("setDeleteRange");

    const date = new Date();
    const day = date.getDay();
    const year = date.getFullYear();
    const month = date.getMonth();

    const formattedRangeSet = computed(() => {
      return props.values.map((range) => {
        return range.map((range) => {
          if (Number.isNaN(Date.parse(range))) {
            const time = range.split(":");
            return moment()
              .set({
                hour: time[0],
                minute: time[1],
                second: 0,
                millisecond: 0,
              })
              .format("ddd MMM DD YYYY HH:mm:ss");
          }
          return moment(range).format("ddd MMM DD YYYY HH:mm:ss");
        });
      });
    });

    const rangeBar = RangeBar({
      min: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("ddd MMM DD YYYY HH:mm:ss"),
      max: moment()
        .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
        .format("ddd MMM DD YYYY HH:mm:ss"),
      valueFormat: function (ts) {
        return moment(ts).format("HH:mm");
      },
      valueParse: function (date) {
        return moment(date).valueOf();
      },
      values: formattedRangeSet.value,
      label: function (a) {
        var hourStart = a[0].split(":")[0],
          minuteStart = a[0].split(":")[1],
          hourStop = a[1].split(":")[0],
          minuteStop = a[1].split(":")[1],
          start = moment([year, month, day, hourStart, minuteStart]),
          stop = moment([year, month, day, hourStop, minuteStop]),
          diff = stop.diff(start, "seconds");

        if (diff == 0) return "24:00";

        return moment([year, month, day, 0, 0]).seconds(diff).format("HH:mm");
      },
      snap: 1000 * 60 * 15,
      minSize: 1000 * 60 * 60,
      maxRanges: 6,
      bgLabels: 12,
      indicator: function (bar, indicator, recalc) {
        if (recalc) setInterval(recalc, 500);
        return moment()
          .hours(Math.floor(24 * Math.random()))
          .format("LLLL");
      },
    })
      .on("changing", function (ev, ranges, changed) {
        const rangeArray = [...ranges];
        if (rangeArray.length) {
          if (rangeArray[rangeArray.length - 1][1] === "00:00") {
            rangeArray[rangeArray.length - 1].pop();
            rangeArray[rangeArray.length - 1].push("24:00");
          }
        }

        emit("changeRange", { day: props.id, ranges });
      })
      .on("change", function (ev, ranges, changed) {
        const rangeArray = [...ranges];
        if (rangeArray.length) {
          if (rangeArray[rangeArray.length - 1][1] === "00:00") {
            rangeArray[rangeArray.length - 1].pop();
            rangeArray[rangeArray.length - 1].push("24:00");
          }
        }

        emit("changeRange", { day: props.id, ranges });
      });

    watch(
      () => props.values,
      () => {
        if (deletedRangeIndex.value == null) return;
        rangeBar.ranges.splice(deletedRangeIndex.value, 1);
        let barLabels = document.querySelectorAll(
          `#${props.id} .elessar-range`
        );
        if (barLabels.length) barLabels[deletedRangeIndex.value].remove();
        setDeleteRange(null);
      }
    );

    const initSlider = () => {
      $("#" + props.id).html(rangeBar.$el);
    };

    onMounted(() => {
      initSlider();
    });
  },
};
</script>

<style>
.elessar-handle {
  display: block;
  background: rgb(128, 136, 128);
  border-radius: 2px;
  height: 100%;
  width: 5px;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 12px !important;
}
.elessar-handle .content {
  position: absolute;
  top: -9px;
  left: -4px;
  color: #333;
  font-size: 12px !important;
}
/* .elessar-handle:first-child {
    left: 0;
    top: 1px;
    background: #fff;
    border: 1px solid #1AB394;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .elessar-handle:last-child {
    right: 0;
    top: 1px;
    background: #fff;
    border: 1px solid #1AB394;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translateY(-50%);
  } */
.elessar-handle:first-child {
  background: #000;
  border: 1px solid #000;
  width: 3px;
  height: 1.2rem;
  left: 0;
}
.elessar-handle:last-child {
  right: 0;
  background: #000;
  border: 1px solid #000;
  width: 3px;
  height: 1.2rem;
}
.elessar-vertical .elessar-handle {
  height: 5px;
  width: 100%;
  left: 0;
  right: 0;
  top: auto;
  bottom: auto;
  cursor: ns-resize;
}
/* .elessar-vertical .elessar-handle:first-child {
    top: 0;
  }
  .elessar-vertical .elessar-handle:last-child {
    bottom: 0;
  } */
.elessar-indicator {
  position: absolute;
  background: black;
  border-color: white;
  border-style: solid;
  border-width: 0 1px;
  width: 2px;
  height: 100%;
  z-index: 15;
  opacity: 0;
}
.elessar-vertical .elessar-indicator {
  width: 100%;
  height: 2px;
}
.elessar-range {
  position: absolute;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgba(26, 179, 148, 0.85);
  width: 0;
  font-size: 12px !important;
  font-weight: bold;
  z-index: 40;
}

.elessar-barlabel {
  opacity: 1;
  display: flex;
  justify-content: center;
  color: #404040;
}

.elessar-vertical .elessar-range {
  width: auto;
  height: 0;
}
.elessar-label {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  border-left: 1px solid #999;
  padding-left: 10px;
}
.elessar-readonly {
  opacity: 0.6;
  cursor: default !important;
}
.elessar-phantom {
  background-color: transparent;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer !important;
}
.elessar-rangebar {
  position: relative;
  height: 1.2rem;
  line-height: 1.2rem;
  background: rgb(216, 216, 216);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 5px;
  font-size: 12px !important;
}

.closed .elessar-rangebar {
  background: rgb(230, 36, 36);
}

.elessar-delete-confirm {
  background: red;
}
body.elessar-resizing,
body.elessar-dragging {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body.elessar-resizing,
body.elessar-resizing .bar,
body.elessar-resizing .handle {
  cursor: ew-resize !important;
}
body.elessar-resizing.elessar-resizing-vertical,
body.elessar-resizing.elessar-resizing-vertical .bar,
body.elessar-resizing.elessar-resizing-vertical .handle {
  cursor: ns-resize !important;
}
body.elessar-dragging,
body.elessar-dragging .bar,
body.elessar-dragging .handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}
</style>